import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContractService } from '../../contract/contract.service';
import { TimesheetService } from '../../ts/timesheet.service';
import { TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { UserService } from '../../user.service';
import { Subject, map, tap, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { T7eService } from '../../t7e/t7e.service';
import * as moment from 'moment';
import { DataService } from '../../data.service';
import { ProductionService } from '../../prod/production.service';
import { invoiceMainualLink } from '../../config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  private _destroy$ = new Subject<void>();

  constructor(
    private dataSvc: DataService,
    private prodSvc: ProductionService,
    private contractSvc: ContractService,
    private tsSvc: TimesheetService,
    private userSvc: UserService,
    private appSvc: AppService,
    private cdr: ChangeDetectorRef,
    private t7e: T7eService,
  ) { 
    this.appSvc.pageTitle$.next(this.t7ePageTitle)
    //tsSvc.listTs({})
  }

  get debug() { return this.appSvc.debug }
  get lang() { return this.t7e.lang }
  isTsLoading$ = this.tsSvc.tsListLoading$
  isCrew$ = this.userSvc.isCrew$
  get isDeptAdmin() { return this.userSvc.isDeptAdmin }
  get isOnlyDeptAdmin() { return this.userSvc.isOnlyDeptAdmin }
  get isDeptHead() { return this.userSvc.isDeptHead }
  get isProdMgr() { return this.userSvc.isProdMgr }
  get isMoreThanDeptHead() { return this.userSvc.isMoreThanDeptHead }
  prodTitle = this.prodSvc.prodDefaults?.prname
  appVersionCheckingMessage$ = this.appSvc.appVersionCheckingMessage$
  isAppVersionChecking$ = this.appSvc.isAppVersionChecking$
  invoiceMainualLink = invoiceMainualLink

  loggedinUser$ = this.userSvc.loggedinUser$
  users$ = this.userSvc.allUsers$
  usersInLoggedInUsersDept$ = this.contractSvc.usersInLoggedInUsersDept$
  pendingUsers$ = this.users$.pipe(map(u => u?.filter(u => u?.ugm?.filter(g => g?.ugid).length === 0).filter(this.contractSvc.hasBasicData)))
  isUsersLoading$ = this.userSvc.allUsersLoading$
  get isProfileComplete() { return this.userSvc.isProfileComplete() }
  isLangSelected = this.userSvc.isLangSelected

  userContracts$ = this.contractSvc.userContracts$
  contracts$ = this.contractSvc.allContracts$
  deptContracts$ = this.contractSvc.deptContracts$
  prodMgrPendingContracts$ = this.contractSvc.prodMgrPendingContracts$
  finalApprovedContracts = this.contractSvc.finalApprovedContracts$
  financePendingContracts$ = this.contractSvc.financePendingContracts$
  readyToSignContracts$ = this.contractSvc.readyToSignContracts$
  atCrewOwnContracts$ = this.contractSvc.atCrewOwnContracts$
  contractsLoading$ = this.contractSvc.allContractsLoading$
  loggedUserHasBasicData$ = this.contractSvc.loggedUserHasBasicData$
  isFinanceApprovalNeededConfig$ = this.contractSvc.isFinanceApprovalNeededConfig$

  tsAtCrew$ = this.tsSvc.ownTsAtCrew$
  deptTsList$ = this.tsSvc.deptTsList$
  
  get pendingOwnTimesheets() { return this.tsSvc.ownTsList$.value?.filter(ts => ts?.tsstatus == TimesheetStatus.attSent) }
  get pendingProdMgrTimesheets() { return this.tsSvc.tsList?.filter(ts => this.tsSvc.isAtProdMgr(ts)) }
  get tsList() { return this.tsSvc.ownTsList$.value?.filter(ts => ts.tsstatus! > 0) }
  tsNotSent$ = this.tsSvc.tsList$.pipe(map(ts => ts?.filter(ts => !this.tsSvc.isSent(ts) && ts?.tsstatus !== TimesheetStatus.deleted)))
  tsApproved$ = this.tsSvc.tsList$.pipe(map(ts => ts?.filter(ts => ts?.tsstatus === TimesheetStatus.approved)))
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // ez azért kell, mert refresh után nem frissül a dashboard
    this.dataSvc.initData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((_) => {
      this.cdr.detectChanges()
      
    })
  }

  reloadAppData(e?: MouseEvent) {
    e?.stopPropagation();
    this.dataSvc.getAppData()
  }

  reloadUser(e?: MouseEvent) {
    e?.stopPropagation()
    this.reloadCon() // azért kell, mert az, hogy a user pending-e, attól is függ, hogy van-e hozzá contract
    this.userSvc.listUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe((_) => {
        this.cdr.detectChanges()
      })
  }

  reloadTs(e?: MouseEvent) {
    e?.stopPropagation()
    this.tsSvc.listTs({})
  }
  reloadCon(e?: MouseEvent) {
    e?.stopPropagation()
    this.contractSvc.listContracts({})
  }

  getTSDate(ts?: TimesheetParsed | null): string | undefined {
    return ts
      ? moment(ts?.dStartDate).locale('hu').format('M/DD')
      : undefined
  }

  checkForAppUpdates(ev: MouseEvent) {
    ev.stopPropagation()
    this.appSvc.checkForAppUpdate()
  }

  t7ePageTitle = this.t7e.getTranslation('app-dashboard', 'page-title', 'innerText', null, null, 'Főoldal')

  t7eCongratsTitle = this.t7e.getTranslation('app-dashboard', 'congrats-you-are-in', 'title', null)
  t7eCongratsDescr = this.t7e.getTranslation('app-dashboard', 'congrats-you-are-in', 'description', null)

  t7eSectionYourData = this.t7e.getTranslation('app-dashboard', 'section-your-data', 'title', null)
  t7eSectionDeptData = this.t7e.getTranslation('app-dashboard', 'section-dept-data', 'title', null)
  t7eSectionProduction = this.t7e.getTranslation('app-dashboard', 'section-production', 'title', null)
  t7eSectionFinance = this.t7e.getTranslation('app-dashboard', 'section-finance', 'title', null)
  t7eSectionReports = this.t7e.getTranslation('app-dashboard', 'section-reports', 'title', null)

  t7eYouHave = this.t7e.getTranslation('app-dashboard', 'you-have', 'innerText', null)
  t7eYouHaveXContracts = this.t7e.getTranslation('app-dashboard', 'you-have-x-contracts', 'innerText', null)

  t7eProfileIncompleteTitle = this.t7e.getTranslation('app-dashboard', 'box-profile-complete-your-profile', 'innerText', null)

  t7eContractsAtCrewTitle = this.t7e.getTranslation('app-dashboard', 'box-contracts-at-crew', 'title', null)
  t7eTsAtCrewTitle = this.t7e.getTranslation('app-dashboard', 'box-ts-at-crew', 'title', null)
}
