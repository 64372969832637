import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService, ButtonStatus, DbCallError } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { Timesheet } from '../../models/timesheet';
import { T7eService } from '../../t7e/t7e.service';
import { UserService } from '../../user.service';
import { map, takeUntil } from 'rxjs';

@Component({
  selector: 'app-new-timesheet',
  templateUrl: './new-timesheet.component.html',
  styleUrls: ['./new-timesheet.component.scss']
})
export class NewTimesheetComponent implements OnInit {

  private _dates: Date[] = []
  @Input() 
  set dates(value: Date[] | null | undefined) {
    if (value) {
      this._dates = value
    }
  }
  get dates(): Date[] | null | undefined {
    return this._dates
  }
  
  @Output() selectedDateChange = new EventEmitter<Date[]>()

  isDebug = this.appSvc.debug
  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  isFinanceApprovalNeededConfig = this.contractSvc.isFinanceApprovalNeededConfig
  selectedContract$ = this.contractSvc.selectedContract$
  selectedTs: Timesheet | null=  null
  isSelectedApproved$ = this.selectedContract$
    .pipe(
      map(c => this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts$.value,c))
  )

  constructor(
    private userSvc: UserService,
    private contractSvc: ContractService,
    private appSvc: AppService,
    private t7e: T7eService,
  ) {
    this.appSvc.pageTitle$.next(this.t7ePageTitleNewTs)
  }

  ngOnInit(): void {
    this.selectedContract$.subscribe(c => {
      this.selectedTs = {
        tsid: null,
        conid: c?.conid,
        usname: c?.usname,
        depname: c?.depname,
        role: c?.role,
       }
     })

  }

  onSelectedDateChanged(dates: Date[]): void {
    this.selectedDateChange.emit(dates)
  }

  /** T7E */
  t7ePageTitleNewTs = this.t7e.getTranslation('app-new-timesheet', 'page-title', 'innerText', 'Új Timesheet')

}

