<div class="dept-ts">
    <h1 class="page-title" t7e="dept">Részleg Timesheetjei</h1>

    <div class="panels">
        <div class="filters">
            <app-day-selector (selectedDateChange)="onSelectedDateChanged($event)" [isYesNo]="false"
                [tsList$]="deptTsList$" (reload)="reloadTsList()" class="day-selector mb-1">
            </app-day-selector>

            <div [formGroup]="form" class="contract-filter-wrapper">
                <mat-form-field class="contract-search mr-1"*ngIf="((allContracts$ | async)?.length || 0) > 7">
                    <input matNativeControl #searchContracts formControlName="searchContracts" [placeholder]="'search' | t7e">
                    <span matPrefix><mat-icon class="prefix-icon">search</mat-icon></span>
                    <span matSuffix><mat-icon (click)="resetSearchContracts()" class="reset-search-contracts suffix-icon">close</mat-icon></span>
                </mat-form-field>
                
                <mat-checkbox formControlName="disabledContracts" *ngIf="existDisableContracts$ | async">
                    {{ 'disabled-contracts' | t7e }}
                </mat-checkbox>
            </div>

            <div class="contract" *ngFor="let contract of allContractsFiltered$ | async"
                (click)="selectContract(contract)" 
                [class.selected]="contract.conid== (selectedContract$ | async)?.conid"
                [class.not-clickable]="!isContractClickable(contract)"
                [title]="(allowSelectUnapproved || isContractClickable(contract)) ? '' : 'Ezt a tevékenységet még nem jóváhagyta jóvá a pénzügy, ezért nem vihetsz rá be timesheetet'"
            >
                <div class="color-indicator" [ngClass]="contractIndicatorClass(contract)" title="Timsheet status"></div>
                <div class="data">
                    <div class="name">
                        {{ contract.usname }}
                    </div>
                    <div class="role">
                        {{ contract.role }}
                    </div>
                </div>
                <div class="selector">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <button mat-raised-button class="contract reload-contract-button" 
                (click)="reloadContracts()" type="button">
                <mat-spinner [diameter]="20" *ngIf="(contractsLoading$ | async)" class="spinner"></mat-spinner>
                <mat-icon *ngIf="!(contractsLoading$ | async)">refresh</mat-icon>
                <span t7e="btn-refresh-text">Frissít</span>
            </button>
        </div>
        <div class="timesheet-editor" *ngIf="filtersSlected; else noFiltersSelected">
            <div class="ts-list">
                <div class="select-ts" *ngIf="!(selectedTs$ | async)"><span t7e="select-ts">Válassz Timesheetet</span><mat-icon>arrow_right_alt</mat-icon></div>
                <app-timesheet *ngFor="let ts of filteredTsList$ | async"
                    [timesheet]="ts"
                    [isSelected]="(selectedTs$ | async)?.tsid === ts.tsid"
                    [showRole]="false" [showDept]="false"
                    (click)="onTsSelected(ts)">
                </app-timesheet>
                <div class="ts add-ts" (click)="addNewTs()" [class.selected]="!(selectedTs$ | async)?.tsid"
                    *ngIf="(selectedDays$ | async)?.length===1 && (filteredTsList$ | async)?.length===0"
                    t7e="new-ts-button">
                    New TS
                </div>
            </div>

            <span *ngIf="debug">{{ selectedTs$ | async | json }}</span>
            <app-edit-timesheet *ngIf="((filteredTsList$ | async)?.length!>0 && (selectedTs$ | async)) || ((selectedDays$ | async)?.length===1 && (filteredTsList$ | async)?.length===0)"
                [timesheet]="selectedTs$ | async"
                [showStatus]="false"
                [showContract]="false"
                [canAddDate]="false"
                layout="grid-form"
                class="edit-timesheet">
            </app-edit-timesheet>
        </div>
        <ng-template #noFiltersSelected>
            <div class="no-filters-selected">
                <mat-icon>keyboard_backspace</mat-icon>
                <span t7e="no-filters-selected" class="ml-1">Válassz napot és stábtagot!</span>
            </div>
        </ng-template>
    </div>

    
        
</div>