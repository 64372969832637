import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, concat, delay, distinctUntilChanged, filter, map, Observable, of } from 'rxjs';
import { AppService, DbCallError } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { CTHttpResponse, DataService, EditLevels, FileUploadResponse, FlexEditTypes, FlexTables } from '../../data.service';
import { FlexTableProperty, PropItem } from '../../models/table-property';
import { UserService } from '../../user.service';
import { FileRestrictions, SuccessEvent, ErrorEvent, RemoveEvent } from "@progress/kendo-angular-upload";
import { FlexFieldsService } from './flex-fields.service';
import { T7eLangs, T7eService } from '../../t7e/t7e.service';
import { Contract } from '../../models/contract';
import { DocService } from '../../doc.service';

@Component({
  selector: 'app-flex-fields',
  templateUrl: './flex-fields.component.html',
  styleUrls: ['./flex-fields.component.scss']
})
export class FlexFieldsComponent implements OnInit {
  @Input() formGroup: FormGroup | null = null
  @Input() tableid: number = 0
  @Input() formFieldStyles: { [key: string]: string } = {}
  @Input() formGroupStyles: { [key: string]: string } = {}
  @Input() showReadonlyFields: boolean = false
  
  /**
   * Csak az itt felsorolt mezőket jeleníti meg
   */
  @Input() allowedPropCodes?: string[] | null = null
  @Input() isEditable?: boolean = true
  /**
   * Ha false, akkor nem validálom a mezőket
   */
  @Input() validate$?: BehaviorSubject<boolean>
  @Input() uploadFolderName?: string
  @Input() uploadFolderId?: string
  @Output() fileUploadSuccess = new EventEmitter<FileUploadResponse>()
  @Output() fileUploadError = new EventEmitter<ErrorEvent>()

  readonly baseUrl = this.dataSvc.apiUrl // 'http://kovacsek.selfip.net:18080/' // this.dataSvc.apiUrl //
  get uploadSaveUrl() {
    const folderQS = this.uploadFolderId
      ? '&folderid=' + this.uploadFolderId
      : '&foldername=' + this.uploadFolderName || 'ismeretlen folder'
    return this.dataSvc.uploadSaveUrl + folderQS
  }
  uploadRemoveUrl = this.dataSvc.uploadRemoveUrl
  aPropItems$ = this.appSvc.aPropItems$

  KENDOCONTROL_NAME = this.flexSvc.KENDOCONTROL_NAME

  constructor(private fb: FormBuilder,
    private contractSvc: ContractService,
    private appSvc: AppService,
    private usersSvc: UserService,
    private dataSvc: DataService,
    private flexSvc: FlexFieldsService,
    private docSvc: DocService,
    private t7e: T7eService,
  ) {
    this.isAllowedPropCode = this.isAllowedPropCode.bind(this)
    this.sortFields = this.sortFields.bind(this)
  }

  get debug() { return this.appSvc.debug }
  get lang() { return this.t7e.lang }

  flexFields$ = new BehaviorSubject<FlexTableProperty[] | undefined>(undefined)
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ["pdf", "jpg", "jpeg", "png", "tiff"],
  };

  ngOnInit(): void {
    if (!this.formGroup) {
      throw 'form: FormGroup nélkül nem tudok űrlapot készíteni'
    }
    if (!this.tableid) {
      throw 'tableid nélkül nem tudom, hogy melyik mezőkből csináljak űrlapot'
    }

    this.appSvc.tableProperties$
      .pipe(
        filter(props => !!props),
        map(props => props
          ?.filter(p => p.tableid === this.tableid)
          ?.filter(p => this.formGroup?.get(p.propcode)) // csak amihez van formcontrol
          ?.filter(p => this.showReadonlyFields || this.canWriteField(p))
          ?.sort(this.sortFields)
        ),
    )
      .subscribe(this.flexFields$)
    
    this.flexFields$.subscribe(f => {
      if (!f) throw 'nincsenek flex mezők az appSvc-ben'
      console.log('Most újra beállítom a flexFields enable/disable-t')
      Object.keys(this.formGroup!.controls).forEach(c => {
        if (!this.canWriteField(this.appSvc.tableProperties
          ?.find(p => (p.propcode === c || p.propcode + this.flexSvc.KENDOCONTROL_NAME === c)) || null)) {
          this.formGroup?.get(c)?.disable()
        } else {
          this.formGroup?.get(c)?.enable()
        }

        // Az upload controllok mellé csinálunk egy ikret, ami a kendo-upload formControlja lesz
        // A kendo-é tárolja a feltöltés eredeti adatait, a 'propcode' pedig a linket, amit menteni kell
        const flexField = f.find(field => field.propcode == c)
        if (flexField?.edittype == FlexEditTypes.upload) {
          this.formGroup?.addControl(flexField.propcode + this.KENDOCONTROL_NAME,new FormControl(null, /* ez a control nem kap értéket betöltődéskor, ezért a required validátor hibára futna;(!flexField.optional && Validators.required) ||*/ null))
        }
      })

    })

    if (!this.validate$) this.validate$ = new BehaviorSubject<boolean>(false)
    this.validate$
    ?.pipe(distinctUntilChanged())
    .subscribe(v => {
      console.count('validate$')
      // loop through all controls and set their validation according to the validate$ value and the field's optional property
      Object.keys(this.formGroup!.controls).forEach(c => {
        const field = this.appSvc.tableProperties?.find(p => p.propcode == c)
        if (!field) return
        if (v && !field.optional && field.edittype != FlexEditTypes.upload) {
          this.formGroup?.get(c)?.setValidators(Validators.required)
        } else {
          this.formGroup?.get(c)?.clearValidators()
        }
        this.formGroup?.get(c)?.updateValueAndValidity()
      })
      // mark form as touched
      this.formGroup?.markAllAsTouched()
    })
  }

  /** Sorter function for Array.sort */
  private sortFields(a: FlexTableProperty, b: FlexTableProperty): number {
    // if there are `allowedPropCodes` then sort the fields according to the order of the `allowedPropCodes`
    if (this.allowedPropCodes) {
      const aIndex = this.allowedPropCodes.indexOf(a.propcode)
      const bIndex = this.allowedPropCodes.indexOf(b.propcode)
      return aIndex - bIndex
    } else {
      return ((a.ord || 9999) - (b.ord || 9999)) // rendezés, ha nincs ord, akkor megy a végére
    }
  }

  isAllowedPropCode(propCode: string | FlexTableProperty): boolean {
    if (typeof propCode == 'object') propCode = propCode.propcode
    return !this.allowedPropCodes || this.allowedPropCodes.includes(propCode)
  }

  fileUploaded(e: SuccessEvent, field: FlexTableProperty) {
    console.log('file uploaded', e)
    const response = e.response.body as CTHttpResponse<FileUploadResponse>
    let filename = e.files?.[0].name || 'n/a'
    const url = this.appSvc.appendQS(response.data?.file_url, this.dataSvc.QSPARAM_FILENAME, filename)
    console.log('Feltöltött fájl:', url)
    const fieldFG = this.formGroup?.get(field.propcode)
    if (!fieldFG) console.error('Ismeretlen mező: ' + field.propcode)
    fieldFG?.setValue(url)
    this.fileUploadSuccess.emit(response.data)
  }
  
  fileUploadedError(e: ErrorEvent) {
    console.error(e);
    this.fileUploadError.emit(e)
  }

  public removingDocMap = new Map<string, BehaviorSubject<boolean>>();
  public removingDocErrorMap = new Map<string, BehaviorSubject<DbCallError>>();
  removeFile(e: RemoveEvent | null, propcode: string): void {
    e?.preventDefault?.()
    if (!this.removingDocMap.get(propcode)) this.removingDocMap.set(propcode, new BehaviorSubject<boolean>(true))
    else this.removingDocMap.get(propcode)!.next(true); // Emit true to indicate that the file is being removed
    if (!this.removingDocErrorMap.get(propcode)) this.removingDocErrorMap.set(propcode, new BehaviorSubject<DbCallError>(null))
    else this.removingDocErrorMap.get(propcode)!.next(null);

    this.dataSvc.removeDoc(this.getFileid(propcode))
      .subscribe({
        next: _ => {
          this.removingDocMap.get(propcode)!.next(false)
          this.removingDocErrorMap.get(propcode)!.next(false)
          const fieldFG = this.formGroup?.get(propcode)
          if (!fieldFG) console.error('Ismeretlen mező törléskor: ' + propcode)
          fieldFG?.setValue(null)
          fieldFG?.markAsDirty()
        },
        error: err => {
          this.removingDocMap.get(propcode)!.next(false)
          this.removingDocErrorMap.get(propcode)!.next(err)
        }
      })
  }

  getUrl(propcode: string): string | null {
    return this.formGroup?.get(propcode)?.getRawValue() || null
  }

  getFilename(propcode: string): string | null {
    return this.appSvc.extractQSValue(this.getUrl(propcode), this.dataSvc.QSPARAM_FILENAME) || ''
  }

  getFolderUrl(): string {
    return this.docSvc.getDriveLink(this.uploadFolderId || null,'folder')
  }

  getFileid(propcode: string): string | null {
    const url = this.formGroup?.get(propcode)?.getRawValue()
    if (!url) return null
    const parts = url.split('/')
    if (parts.length < 6) return url
    return parts[5]
  }

  getPropDesc(propcode: string): string | null {
    const prop = this.appSvc.flexProps?.find(p => p.propcode == propcode)
    if (this.t7e.lang == 'en') return prop?.propdesceng || prop?.propdesc || null
    return prop?.propdesc || prop?.propdesceng || null
  }

  notifByRule(propcode: string): string | null {
    const tableProp = this.flexFields$?.value?.find(f => f.propcode == propcode)
    if (!tableProp) return null
    const selectedPropItemNr = this.formGroup?.get(propcode)?.getRawValue() as string
    const propItem = this.appSvc.aPropItems$[tableProp.propid]?.value?.find(p => p.picode.toString() == selectedPropItemNr)
    try {
      const rules: { notification: Record<T7eLangs, string> } | null = typeof propItem?.pirules === 'string'
        ? JSON.parse(propItem?.pirules || '{}')
        : propItem?.pirules
      return rules?.notification[this.t7e.lang] || null
    } catch (error) {
      return null
    }
  }

  private canWriteField(prop: FlexTableProperty | null): boolean {
    if (!prop) return false
    if (this.usersSvc.isDev) return true
    if (this.usersSvc.isProdMgr) return true
    if (this.usersSvc.isProducer) return false
    if (this.usersSvc.isCrew 
      && (prop.level == EditLevels.crewMember || prop.level === null)
      && this.isEditable) return true
    return false
  }

  isEnabledPropItem(propItem?: PropItem | null): boolean {
    return propItem?.pistatus?.toString() !== '0'
  }

  /** T7E */
  t7eSelect = this.t7e.getTranslation('app-flex-fields', 'option-select', 'innerText', null)
  t7eSelectFile = this.t7e.getTranslation('kendo-upload-messages', 'select', 'innerText', null)
  t7eDropFilesHere = this.t7e.getTranslation('kendo-upload-messages', 'drop-files-here', 'innerText', null)
  t7eCancel = this.t7e.getTranslation('kendo-upload-messages', 'cancel', 'innerText', null)
  t7eClearSelectedFiles = this.t7e.getTranslation('kendo-upload-messages', 'clear-selected-files', 'innerText', null)
  t7eRemove = this.t7e.getTranslation('kendo-upload-messages', 'remove', 'innerText', null)
  t7ePause = this.t7e.getTranslation('kendo-upload-messages', 'pause', 'innerText', null)
  t7eResume = this.t7e.getTranslation('kendo-upload-messages', 'resume', 'innerText', null)
  t7eRetry = this.t7e.getTranslation('kendo-upload-messages', 'retry', 'innerText', null)
  t7eUploadSelectedFiles = this.t7e.getTranslation('kendo-upload-messages', 'upload-selected-files', 'innerText', null)
  t7eFileStatusFailed = this.t7e.getTranslation('kendo-upload-messages', 'file-status-failed', 'innerText', null)
  t7eFileStatusUploaded = this.t7e.getTranslation('kendo-upload-messages', 'file-status-uploaded', 'innerText', null)
  t7eHeaderStatusPaused = this.t7e.getTranslation('kendo-upload-messages', 'header-status-paused', 'innerText', null)
  t7eHeaderStatusUploaded = this.t7e.getTranslation('kendo-upload-messages', 'header-status-uploaded', 'innerText', null)
  t7eHeaderStatusUploading = this.t7e.getTranslation('kendo-upload-messages', 'header-status-uploading', 'innerText', null)
}
